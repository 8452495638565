import { usePostHog } from "posthog-js/react";
import { createContext, useContext, useState } from "react"
import { useMutation, useQuery } from "react-query";
import { site } from "src/client/api";
import { setLoginSource } from "src/utils/helper";
import { useUser } from "./useUser";

const loginToWorkSpaceContext = createContext(null)

export const LoginToWorkSpaceProvider = ({ children }) => {
  const posthog = usePostHog()
  const { user } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
      enabled: !!user,
  });
  const workspaceLink = (sitetoview, page, sid, app = "app") => {
    return sid ?
      `https://${sitetoview}/${app}/${page}?sid=${sid}&posthog_session_id=${posthog.get_session_id()}` :
      `https://${sitetoview}/${app}/${page}`
  }
  var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
  
  const [websid, setwebsid] = useState("")
  const [iframeSrc, setIframeSrc] = useState("")
  const { data: loadAdmin, refetch } = useQuery([`loadAdmin`, sitetoview], () => {
    return site.login(sitetoview, 'Admin')}, {
      onSuccess: (res) => {
        setLoginSource(sitetoview);
      },
  });

  var sid = loadAdmin?.data?.message.sid;
  const { mutate: loginAsAdmin } = useMutation('loginAsAdmin', ({ name, reason }) => site.login(name, reason), {
    onSuccess: (res) => {
      const { sid, site } = res.data.message;
      if (sid && site) {
        setwebsid(sid)
        window.open(workspaceLink(site, 'home', websid), '_blank');
      }
    }
  });

  const { mutate: loginOnIframe } = useMutation('loginAsAdmin', ({ name, reason }) => site.login(name, reason), {
    onSuccess: (res, variables) => {
      const { sid, site } = res.data.message;
      const { pageid } = variables;
      if (sid && site) {
        setwebsid(sid);
        const iframeURL = workspaceLink(site, pageid, websid);
        setIframeSrc(iframeURL);
      }
    }
  });

  const OpenPage = (page, isBlank = true, app = "app") => {
    window.open(workspaceLink(sitetoview, page, sid || null, app), isBlank ? '_blank': '_self');
  }

  const loginNow = (page) => {
    setLoginSource(sitetoview);
    if (page !== undefined) {
      if (websid == "") {
        loginOnIframe({ name: sitetoview, reason: "Login", pageid: page });
      } else {
        const iframeURL = workspaceLink(sitetoview, page, websid);
        setIframeSrc(iframeURL);
      }
    }
  }

  return <loginToWorkSpaceContext.Provider value={{ 
      loadAdmin, 
      refetch, 
      sites,  
      loginNow, 
      loginAsAdmin, 
      loginOnIframe, 
      iframeSrc, 
      setIframeSrc,
      OpenPage,
      sitetoview,
      sid,
      websid,
      workspaceLink
  }}>
      {children}
  </loginToWorkSpaceContext.Provider>
}

export const useLoginToWorkSpace = () => useContext(loginToWorkSpaceContext)