import TeamMembers from "src/pages/teams/teammembers";
import Overview from "./Overview";
import PurchaseHistory from "./PurchaseHistory";
import AllTeams from "src/pages/teams/allteams";
import Teams from "src/pages/teams/teams";
import Checkout from "./Checkout";
import Upgrade from "./Upgrade";
import InvoiceTable from "src/components/invoiceTable";
import BillingAddress from "./BillingAddress";
import MyAccountForm from "./MyAccountForm";

export const ACCOUNT_MENUS = [
  {
    title: "My Account",
    element: <MyAccountForm />,
    path: "/my-account"
  },
  {
    title: "Overview",
    element: <Overview />,
    path: "/billing/overview",
  },
  {
    title: "Unpaid Invoice",
    element: <PurchaseHistory filter="Unpaid"/>,
    path: "/billing/unpaid-invoice"
  },
  {
    title: "Purchase history",
    path: "/billing/purchase-history",
    children: [
      {
        title: "Purchase history",
        element: <PurchaseHistory />,
        index: true
      },
      {
        title: "Invoice",
        element: <InvoiceTable />,
        path: "/billing/purchase-history/:invoiceId"
      }
    ]
  },
  {
    title: "Billing address",
    element: <BillingAddress />,
    path: "/billing/billing-address"
  },
  {
    title: "Usages",
    element: <h1>USAGES</h1>,
    path: "/billing/usages"
  },
  {
    title: "Invite Team Members",
    element: <Teams />,
    path: "/teams/invite"
  },
  {
    title: "Manage Team Members",
    element: <TeamMembers />,
    path: "/teams/member"
  },
  {
    title: "Switch WorkSpace",
    element: <AllTeams />,
    path: "/teams/switch-workspace"
  },
  {
    title: "Logout",
    element: null,
    path: "logout"
  },
  {
    title: "Upgrade",
    element: <Upgrade />,
    path: "/upgrade"
  },
  {
    title: "Checkout",
    element: <Checkout />,
    path: "/upgrade/checkout"
  }
]