import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { useUser } from "../../hooks/useUser";
import Loading from "src/components/ui/loading";
import { team } from "src/client/api";
import { AccountModalButton, AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";

export default function AllTeams() {
  const { auth } = useUser();
  const teamnames = auth?.teams
  const TeamCard = (lteam) => {
    return (
      <AccountModalButton 
        className="p-4"
        icon={<Avatar>
          <AvatarImage />
          <AvatarFallback>{lteam.user[0].toUpperCase()}</AvatarFallback>
        </Avatar>}
        title={lteam.user}
        desc={auth?.team?.user === lteam.user ? "Owner" : "Member"}
      />
    )
  }
  return (
    <div className="flex flex-col gap-10">
      {auth ? (
        <>
          <AccountModalMenus title="CURRENT TEAM">
            <TeamCard {...auth.team} />
          </AccountModalMenus>
          <AccountModalMenus title="ALL YOUR TEAM" className="bg-white">
            <section className="flex flex-col gap-3">
              {teamnames.map(t => {
                const handleSwitchTeam = () => team.switch_team(t.name).then(() => window.location.reload());
                return (
                  <AccountModalMenus onClick={handleSwitchTeam}>
                    <TeamCard key={t.name} {...t} />
                  </AccountModalMenus>
                )
              })}
            </section>
          </AccountModalMenus>
        </>
      ) : <Loading />}
    </div>
  )
}