import { createContext, useContext, useEffect, useState } from "react"

const iframeContext = createContext(null)

export const IframeProvider = ({children}) => {
  const [isIframeOpen, setIsIframeOpen] = useState(false)
  const [iframeLink, setIframeLink] = useState("")
  const [openedSidebar, setOpenedSidebar] = useState(
    localStorage.getItem("sidebar-open") === null || localStorage.getItem("sidebar-open") === "true" ? true : false
  )
  const openIframe = (src) => {
    setIsIframeOpen(true)
    setIframeLink(src)
  }
  useEffect(() => {
    if (!isIframeOpen) setIframeLink("")
  }, [iframeLink])

  return <iframeContext.Provider value={{
    isIframeOpen,
    setIsIframeOpen,
    iframeLink,
    openedSidebar,
    setOpenedSidebar,
    openIframe
  }}>
    {children}
  </iframeContext.Provider>
}

export const useIframe = () => useContext(iframeContext)