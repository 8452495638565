import { useState, useEffect } from 'react'
import { useUser } from "../../../hooks/useUser";
import { useMutation, useQuery } from "react-query";
import { site } from "../../../client/api";
import { useNavigate } from "react-router-dom";
import { Progress } from "src/components/ui/progress";
import UpgradeLogo from "src/components/icon-menus/UpgradeLogo";
import { AccountModalButton, AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { Icons } from "src/components/ui/icons";
import { ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import TeamMemberIcon from "src/components/icon-menus/TeamMemberIcon";
import OverviewMenu from './OverviewMenu';
import { useLoginToWorkSpace } from 'src/hooks/useLoginToWorkspace';
import usePurchaseHistory from 'src/hooks/usePurchaseHistory';

export default function Overview() {
  const [numOfAdmin, setNumOfAdmin] = useState(0)
  const [installdApps, setinstalldApps] = useState(0)
  const [doctypeList, setdoctypeList] = useState([])
  const navigate = useNavigate();
  const { sites } = useLoginToWorkSpace()
  const { plan, checkIfPro } = usePurchaseHistory()
  const { user, auth } = useUser();

  const checkout_info = useQuery('checkout_info', () => site.get_web_plans(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const plan_change_logs = useQuery('plan_change_logs', () => site.get_plan_change_logs(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });

  useEffect(() => {
    if (auth?.onboarding.site_created === false || (sites && !sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN)))) {
      navigate('/dashboard/instance-configuration');
    }
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name) {
      if (!checkout_info.data) checkout_info.refetch();
      if (!plan_change_logs.data) plan_change_logs.refetch();
    }
  }, [auth?.onboarding.site_created, checkout_info, plan_change_logs, sites]);

  const { data: siteOverview } = useQuery(['site', `${sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}`], () => site.overview(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), {
    enabled: !!sites?.site_list.length,
    onSuccess: (res) => {
      fetchUsageData(res.domains[0].name);
    }
  });

  const fetchUsageData = async (domain) => {
    try {
      const response = await fetch(`https://${domain}/api/method/erpnext_quota.erpnext_quota.quota.get_list_of_usage`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const dataArray = data.message;
      setNumOfAdmin(dataArray['admin_user_count']);
      setinstalldApps(dataArray['installed_apps_count']);
      setdoctypeList(dataArray['doctype_limit']);
    } catch (error) {
      console.error('Error fetching usage data:', error);
    }
  };

  const installedApps = useQuery('installed_apps', () => site.installed_apps(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const usagePlan = siteOverview?.plan

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty or undefined date
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  const overview_menus = {
    upgrade: {
      title: "Upgrade plan",
      submenus: [
        {
          title: checkIfPro ? "Get Pro+" : "Get More Storage and Features",
          desc: "Get more for your business and features with zaviago pro plan",
          icon: (<UpgradeLogo />)
        },
        {
          title: (<span className="text-link">Upgrade to Pro</span>),
          desc: "Starting from THB 750.00/month",
          icon: null
        }
      ]
    },
    team: {
      title: "Team member",
      submenus: [
        {
          title: (checkIfPro ?
            <>You have <span className="text-darkpurple">5 Pro members</span> and <span className='text-[#56C741]'>10 Pro seats</span> available</> :
            <>You have <span className="text-darkpurple">10 free team members</span></>
          ),
          desc: (checkIfPro ?
            "Get more member seats by adding more seat to your workspace" :
            "Add unlimited free team member to use with your free forever package"
          ),
          icon: (<TeamMemberIcon />)
        },
        {
          title: (<span className="text-link">{checkIfPro ? "Add more seat starting at THB 350.00" : "Invite and share"}</span>),
          desc: checkIfPro ?
            "Per member / month" :
            "Share your workspace with other people for free and work together."
          ,
          icon: null
        }
      ]
    }
  }

  const { upgrade, team } = overview_menus;
  const handleOpenUpgrade = () => navigate('/upgrade');

  return (
    <>
      <section className="flex flex-col gap-y-10">
        <AccountModalMenus title="SUBSCRIPTION FOR YOUR TEAM" desc="Active Plan">
          <AccountModalButton
            className="p-4"
            icon={<Icons.mainIcon className="w-12 h-12"/>}
            title={checkIfPro ? 'Pro Package' : 'Free Trial'}
            titleClassName={checkIfPro ? '' : "font-medium"}
            rightText={`THB ${checkIfPro && plan ? (plan.price_usd).toFixed(2) : '0.00'}`}
            onClick={handleOpenUpgrade}
            desc={<>{checkIfPro ? formatDate(usagePlan?.active_invoice?.[0]?.period_end) || "31 Dec 2025" : "No expired date."}<br />{sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name || "test.zaviago.com"}</>}
          />
        </AccountModalMenus>
        <OverviewMenu title={upgrade.title} submenu={upgrade.submenus} />
        <OverviewMenu title={team.title} submenu={team.submenus} />
        <AccountModalMenus className="px-4 py-2" title="PLAN USAGE" desc={plan ? `Updated on ${new Date(plan?.modified).toISOString().slice(0, 16).replace('T', ' ')}` : ''}>
          <section>
            {doctypeList.length > 0 ? doctypeList.map(p => (
              <>
                <div className="hidden sm:flex items-center justify-between py-3 gap-4" key={p.doctype_name}>
                  <h3 className="w-1/3">{p.doctype_name}</h3>
                  <Progress value={(p.quota_usage / p.quota_limit) * 100} />
                  <div className="flex items-center gap-x-2 w-1/4 justify-end">
                    <p className="text-lightgray-900 text-sm">{p.quota_usage}/{p.quota_limit}</p>
                    <ChevronRight className="text-lightgray-900/30" />
                  </div>
                </div>
                <div className="flex sm:hidden flex-col py-3 gap-1.5">
                  <div className='flex items-center justify-between'>
                    <h3>{p.doctype_name}</h3>
                    <div className="flex items-center gap-x-2 justify-end">
                      <p className="text-lightgray-900 text-sm">{p.quota_usage}/{p.quota_limit}</p>
                      <ChevronRight className="text-lightgray-900/30" />
                    </div>
                  </div>
                  <Progress value={(p.quota_usage / p.quota_limit) * 100} />
                </div>
              </>
            )) : <p className='px-4'>No plan usage</p>}
          </section>
        </AccountModalMenus>
      </section>
    </>
  )
}