import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { MainModal, MainModalHeader } from "src/components/modals/MainModal";
import { ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { cn } from "src/lib/utils";
import { Outlet, useLocation, useNavigate, useRoutes } from "react-router-dom";
import useTopbarMenu from "src/hooks/useTopbarMenu";
import { ACCOUNT_MENUS } from "./menus";
import useModal from "src/hooks/useModal";
import { useEffect, useState } from "react";

export function AccountModalMenus({ title, largeTitle, desc, menus, onClick, onSelect, type, children, className }) {
  return (
    <fieldset>
      {(title || desc) ? (
        <div className="px-4 pb-[7px] text-lightgray-900/60 leading-4">
          {title ? <legend className="text-xs">{title}</legend> : null}
          {desc ? <p className="font-light text-xxs pb-[5px]">{desc}</p> : null}
        </div>
      ) : null}
      {largeTitle ? <h2 className="px-4 pb-3 text-[20px] font-medium text-center sm:text-left">{largeTitle}</h2> : null}
      <section className={cn("bg-lightgray-100 rounded-lg", className)} onClick={onClick}>
        {menus ? (
          <Table>
            <TableBody>
              {menus.map(menu => {
                const handleMenu = () => onSelect(menu);
                return (
                  <TableRow className="cursor-pointer" onClick={handleMenu} key={menu}>
                    <TableCell className={cn("pl-0 pr-2 py-3 flex items-center w-full font-normal text-black text-base", "gap-x-3", { "justify-between": type !== "checkbox" })}>
                      {menu.title}
                      <ChevronRight className="text-lightgray-900/30 w-5 h-5" />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        ) : null}
        {children}
      </section>
    </fieldset>
  )
}

export const AccountModalButton = ({ title, icon, desc, rightText, onClick, className, titleClassName }) => {
  return (
    <div className={cn("justify-between flex cursor-pointer", className)} onClick={onClick}>
      <section className="flex items-center gap-x-4">
        {icon}
        <div>
          <h3 className={cn("text-black text-base text-left", titleClassName)}>{title}</h3>
          <p className="text-lightgray-900/60 text-sm text-left">{desc}</p>
        </div>
      </section>
      <div className="flex items-center gap-x-2">
        <p className="text-lightgray-900 text-sm">{rightText}</p>
        <ChevronRight className="text-lightgray-900/30" />
      </div>
    </div>
  )
}

export const AccountModal = ({ 
  onClickRightButton,
  showLeftButton
}) => {
  const { defaultMenu, checkIfHasForms } = useTopbarMenu();
  const { open, closeModal, isSaving, setIsSaving } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [switchPage, setSwitchPage] = useState("");

  useEffect(() => {
    if (open && typeof open === 'string'){
      navigate(open);
    } else {
      navigate(-100);
      setIsSaving(false)
    }
  }, [open])

  const getMenuTitle = (location) => {
    const matchedMenu = [defaultMenu, ...ACCOUNT_MENUS].find(menu => {
      if (menu.path && menu.path === location.pathname) {
        return true;
      }
      if (menu.children) {
        return menu.children.some(child => {
          return child.path && (child.path === location.pathname || (child.path.includes(':') && location.pathname.startsWith(child.path.split('/:')[0])));
        });
      }
      return false;
    });
    return matchedMenu ? matchedMenu.title : null;
  };

  const title = getMenuTitle(location);
  const handleRightButton = () => onClickRightButton ? onClickRightButton() : closeModal()

  return (
    <MainModal
      onClose={closeModal}
      open={open}
      mainClassName={cn("sf-pro p-6 md:px-[74px] md:py-12 relative", switchPage)}
    >
      <MainModalHeader
        showLeftButton={showLeftButton}
        title={title}
        rightButton={checkIfHasForms ? (isSaving ? "Saving" : "Save") : "Done"}
        onClickRightButton={handleRightButton}
      />
      <ModalRoutes />
      {/* <Outlet /> */}
    </MainModal>
  )
}

const ModalRoutes = () => {
  const { defaultMenu } = useTopbarMenu();
  return useRoutes([defaultMenu, ...ACCOUNT_MENUS])
}