import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function textGradient(gradient){
  const style = {
    background: gradient,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
  return style
}

export const handleSlideActivities = (id, side, y) => {
  const menuList = document.getElementById(id)
  menuList.scrollBy({
    top: 0,
    left: side === "left" ? -y : y,
    behavior: 'smooth'
  })
}

export function todayDate(format){
  const date = new Date()

  const months = {
    th: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
    en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  }

  const days = {
    th: ["วันอาทิตย์", "วันจันทร์", "วันอังคาร", "วันพุธ", "วันพฤหัสบดี", "วันศุกร์", "วันเสาร์"],
    en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  }

  return `${days[format][date.getDay()]}, ${date.getDate()} ${months[format][date.getMonth()]}`
}