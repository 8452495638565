import Loading from 'src/components/ui/loading';
import { AccountModalButton, AccountModalMenus } from './AccountModalMenus';
import { useNavigate } from 'react-router-dom';
import usePurchaseHistory from 'src/hooks/usePurchaseHistory';

export default function PurchaseHistory({ filter }) {
  const navigate = useNavigate();
  let lastDate = ""
  const { userInvoices } = usePurchaseHistory()
  const filteredUserInvoices = filter ? userInvoices?.message.filter(invoice => invoice.status === filter) : userInvoices?.message
  if (!userInvoices?.message) { return <Loading /> }
  return (
    <>
      {filteredUserInvoices?.length > 0 ? (
        <section className="flex flex-col gap-y-3">
          {filteredUserInvoices?.map(invoice => {
            const showDate = invoice.date !== lastDate;
            lastDate = invoice.date;
            const handleSelectInvoice = () => navigate(`/billing/purchase-history/${invoice.name}`)
            return (
              <AccountModalMenus title={showDate && invoice.date} key={invoice.name}>
                <AccountModalButton
                  title={invoice.name}
                  desc={invoice.type}
                  rightText={"THB " + (invoice.total).toFixed(2)}
                  icon={invoice.icon}
                  className="p-4"
                  onClick={handleSelectInvoice}
                />
              </AccountModalMenus>
            );
          })}
        </section>
      ) : <p className='text-center'>The purchase history is empty</p>}
    </>
  )
}