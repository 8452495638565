import { site } from "src/client/api";
import { BillingAddressForm } from "src/components/billingAddressForm";
import useModal from "src/hooks/useModal";
import { useUser } from "src/hooks/useUser";

const BillingAddress = () => {
  const { team } = useUser();
  const { setIsSaving } = useModal()
  const onBillingSubmit = async (values) => {
    try {
      setIsSaving(true)
      const user_address = await site.update_user_address(values);
      if (user_address) setIsSaving(false);
    } catch (error) {}
  }
  return (<BillingAddressForm 
    title="BILLING INFORMATION"
    desc="Update / Edit your personal information"
    onSubmitForm={onBillingSubmit}
    billingAddress={team} 
  />)
}

export default BillingAddress