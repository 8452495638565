import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { site, user } from "src/client/api";
import { useLoginToWorkSpace } from "./useLoginToWorkspace";

const usePurchaseHistory = () => {
  const [userInvoices, setuserInvoices] = useState(null);
  const [userBilling, setUserBilling] = useState(null)
  const { sites } = useLoginToWorkSpace()
  const { data: siteOverview } = useQuery(['site', `${sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}`], () => site.overview(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), {
    enabled: !!sites?.site_list.length
  });
  const plan = siteOverview?.plan?.current_plan;
  const checkIfPro = plan?.name === 'pro'

  const fetchData = async () => {
    if (userInvoices == null) setuserInvoices(await user.getInvoices())
    if (userBilling == null) setUserBilling(await user.getBillingInfo())
  }
  useEffect(() => { fetchData() }, []);
  return { userInvoices, userBilling, plan, checkIfPro };
}

export default usePurchaseHistory