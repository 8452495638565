import React, { createContext, useContext, useState } from 'react';

const MediaCacheContext = createContext();

export const useMediaCache = () => useContext(MediaCacheContext);

export const MediaCacheProvider = ({ children }) => {
  const [images, setImages] = useState({});
  const [videos, setVideos] = useState({});

  const cacheImage = (src) => {
    if (!images[src]) {
      const img = new Image();
      img.src = src;
      setImages((prevImages) => ({
        ...prevImages,
        [src]: img,
      }));
    }
  };

  const cacheImages = (srcArray) => {
    srcArray.forEach((src) => {
      if (!images[src]) {
        const img = new Image();
        img.src = src;
        setImages((prevImages) => ({
          ...prevImages,
          [src]: img,
        }));
      }
    });
  };

  const cacheVideo = (src) => {
    if (!videos[src]) {
      const video = document.createElement('video');
      video.src = src;
      video.preload = 'auto';
      setVideos((prevVideos) => ({
        ...prevVideos,
        [src]: video,
      }));
    }
  };

  const cacheVideos = (srcArray) => {
    srcArray.forEach((src) => {
      if (!videos[src]) {
        const video = document.createElement('video');
        video.src = src;
        video.preload = 'auto';
        setVideos((prevVideos) => ({
          ...prevVideos,
          [src]: video,
        }));
      }
    });
  };

  return (
    <MediaCacheContext.Provider value={{ images, cacheImage, cacheImages, videos, cacheVideo, cacheVideos }}>
      {children}
    </MediaCacheContext.Provider>
  );
};