import { useParams } from "react-router-dom"
import { useState, useRef, useEffect } from "react";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button";
import { team } from "../../client/api";
import { useUser } from "../../hooks/useUser";
import { Skeleton } from "src/components/ui/skeleton";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { Icons } from "src/components/ui/icons";
import { inviteTeamImages } from "src/components/icon-menus/workspace-images";

export default function Teams() {
  const { t } = useTranslation()
  const { auth } = useUser();
  const { id } = useParams()
  const [open, setOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const inviteLinkRef = useRef(null)

  console.log("id", id);

  const copyLinkApps = [
    { title: 'AirDrop', icon: inviteTeamImages.airdrop },
    { title: 'Line', icon: inviteTeamImages.lineMessage },
    { title: 'Mail', icon: inviteTeamImages.mail },
    { title: 'QR Scan', icon: inviteTeamImages.qrscan },
    { title: 'Reminders', icon: inviteTeamImages.reminders }
  ]

  const copyLink = () => {
    inviteLinkRef.current.select()
    document.execCommand('copy')
    setIsCopied(true)
    inviteLinkRef.current.blur()
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <section className="flex flex-col gap-10 items-center w-full">
      <Icons.teamPageIcon />
      <section className="w-full">
        {auth?.team?.invite_code ? (
          <AccountModalMenus className="bg-white relative" title="SHARE LINK">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-3 border-r border-lightgray-700 tracking-sfpro text-sm">
              https://
            </div>
            <Input
              style={{ paddingLeft: "84px" }}
              className="bg-white border border-lightgray-700 text-sm text-link tracking-sfpro h-11"
              value={`${window.location.host}/invite/${auth.team.invite_code}`}
              ref={inviteLinkRef}
              readonly
            />
          </AccountModalMenus>
        ) : <Skeleton className='h-9 w-full mt-6 mb-[10px]' />}
      </section>
      {auth?.team && (
        <section className="w-full">
          <SettingsDialogContent
            setOpen={setOpen}
            open={open}
            initialValues={{
              invite_code_rotation_interval: auth.team.invite_code_rotation_interval,
              invite_code_usage_limit: auth.team.invite_code_usage_limit
            }} />
        </section>
      )}
      <section className="flex flex-col gap-2 items-center w-full">
        <div className="w-full">
          <AccountModalMenus className="p-6 rounded-[23px]">
            <div className="flex justify-center gap-[26px] w-full">
              {copyLinkApps.map(app => (
                <button className="flex flex-col gap-[7px] items-center">
                  <img src={app.icon} alt={app.title}/>
                  <p className="text-[11px] tracking-sfpro">{app.title}</p>
                </button>
              ))}
            </div>
          </AccountModalMenus>
        </div>
        <p className="text-xs text-[#858585] mb-1">{t('or')}</p>
        <Button onClick={copyLink} disabled={isCopied} className="regis-btn">{t(isCopied ? 'Copied' : 'Copy Link')}</Button>
      </section>
    </section>
  )
}

export const SettingsDialogContent = ({ ...props }) => {
  const { getUser } = useUser()
  const { t } = useTranslation()
  const expireAfter = ['12 Hours', '1 Day', '3 Days', '7 Days']
  const maximumUses = ['1 use', '5 uses', '10 uses', '20 uses', '30 uses', 'No Limit']
  const formik = useFormik({
    initialValues: props.initialValues,
    onSubmit: (values) => team.change_team_settings(values).then(() => {
      getUser()
      generateLink()
    })
  });
  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values])
  const generateLink = () => {
    props.setOpen(false)
  }
  return (
    <AccountModalMenus title="EDIT INVITE LINK" className="pl-4">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="flex justify-between items-center mr-4 px-0">
              <label className="text-base">
                {t('teams.expire_after')}
              </label>
              <select
                className='bg-[#7878801F] text-base appearance-none px-[11px] py-1.5 rounded-md text-link text-center !outline-none'
                name="invite_code_rotation_interval"
                value={formik.values.invite_code_rotation_interval}
                onChange={formik.handleChange}
              >
                {expireAfter.map(r =>
                  <option value={r} key={r}>{t(`teams.${r}`)}</option>
                )}
              </select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="flex justify-between items-center mr-4 px-0">
              <label className="text-base">
                {t('teams.maximum_uses')}
              </label>
              <select
                className='bg-[#7878801F] text-base appearance-none px-[11px] py-1.5 rounded-md text-link text-center !outline-none'
                name="invite_code_usage_limit"
                value={formik.values.invite_code_usage_limit}
                onChange={formik.handleChange}
              >
                {maximumUses.map(m =>
                  <option value={m} key={m}>{t(`teams.${m}`)}</option>
                )}
              </select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </AccountModalMenus>
  )
}