import { Button } from "src/components/ui/button"
import { Icons } from "src/components/ui/icons"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs"
import ZaviagoCash from "src/components/icon-menus/upgrade-details/ZaviagoCash"
import Promptpay from "src/components/icon-menus/upgrade-details/Promptpay"
import Krungthai from "src/components/icon-menus/upgrade-details/Krungthai"
import { TableBody, Table, TableCell, TableRow } from "src/components/ui/table"
import { ChevronRight } from "@untitled-ui/icons-react/build/cjs"
import { useEffect, useState } from "react"
import { cn } from "src/lib/utils"
import { useQuery } from "react-query"
import { site } from "src/client/api"
import { useLoginToWorkSpace } from "src/hooks/useLoginToWorkspace"
import { useUser } from "src/hooks/useUser"
import { Skeleton } from "src/components/ui/skeleton"

const Checkout = () => {
  const payment_selections = [
    { title: 'Zaviago Cash', icon: <ZaviagoCash className="mt-2"/>, detail: (<p className="text-lightgray-900/60 text-sm">Zaviago Cash Available <span className="bg-[#F2F2F7] rounded-sm px-1">฿5.00</span></p>)},
    { title: 'QR code Payment', icon: <Promptpay className="ml-3"/>, detail: (<p className="text-lightgray-900/60 text-sm">Scan for QR Code</p>)},
    { title: 'Bank Transfer', icon: <Krungthai className="ml-3"/>, detail: (<p className="text-lightgray-900/60 text-sm">Zaviago Cash Available <span className="bg-[#F2F2F7] rounded-sm px-1">$147.17</span></p>)}
  ]
  const { user } = useUser();
  const { sites } = useLoginToWorkSpace()
  const checkout_info = useQuery('checkout_info', () => site.get_web_plans(sites?.site_list[0]?.name), {enabled: false});
  const pro_details = checkout_info?.data && checkout_info?.data.find(item => item.name === 'pro');
  const plan_change_logs = useQuery('plan_change_logs', () => site.get_plan_change_logs(sites.site_list[0].name), {enabled: false});  
  const [selectedPayment, setSelectedPayment] = useState(payment_selections[0].title)
  const handleRedirectToPro = () => window.location.href = "/payment/plan/pro";

  useEffect(() => {
    if (user && sites?.site_list[0]?.name) {
      if (!checkout_info.data) checkout_info.refetch();
      if (!plan_change_logs.data) plan_change_logs.refetch();
    }
  }, [checkout_info,plan_change_logs]);

  const PaymentsTable = () => {
    return (
      <Table className="relative w-full sm:left-20 sm:w-[calc(100%_-_80px)]">
        <TableBody>
          {payment_selections.map(menu => {
            const handleSelectPayment = () => setSelectedPayment(menu.title);
            return (<TableRow className="hover:bg-transparent relative">
              <TableCell className={cn("flex items-center sm:-ml-20 h-16 p-0 gap-4 relative cursor-pointer", {"text-link": selectedPayment === menu.title})} onClick={handleSelectPayment}>
                <div className='w-16'>{menu.icon}</div>
                <div className="flex items-center">
                  <h3 className="font-normal text-base">{menu.title}</h3>
                  <ChevronRight className="absolute right-4 text-[#AFB0B4]"/>
                </div>
              </TableCell>
              {selectedPayment === menu.title && menu.detail ? 
                <span className="inline-flex pb-4">{menu.detail}</span>
              : null}
            </TableRow>)
          })}
        </TableBody>
      </Table>
    )
  }

  return (
    <section className="flex flex-col items-center">
      <div className="w-full">
        <div className="flex flex-col">
          <Icons.storefrontIcon />
          <p className="text-xs text-lightgray-900/60">Powered by Zaviago Storefront.</p>
        </div>
        <Tabs defaultValue="1 year">
          <TabsList className="grid w-full grid-cols-2 mt-5 h-auto">
            <TabsTrigger value="1 year">1 year (30% Discount)</TabsTrigger>
            <TabsTrigger value="3 months">3 months</TabsTrigger>
          </TabsList>
          <TabsContent value="1 year" className="!outline-none !ring-offset-0 !ring-0">
            <PaymentsTable />
            <div className="mt-7 mb-10 flex items-center justify-between pr-4">
              <div>
                <p className="text-lightgray-900/60 text-sm">Pro Plan (1 Year)</p>
                {pro_details ? <h3 className="text-[28px] font-medium">THB {((pro_details?.price_usd  * 12) * 0.7).toFixed(2)}</h3> : <Skeleton className="h-8 w-[120px]"/>}
              </div>
              <ChevronRight className="text-[#AFB0B4]"/>
            </div>
          </TabsContent>
          <TabsContent value="3 months" className="!outline-none !ring-offset-0 !ring-0">
            <PaymentsTable />
            <div className="mt-7 mb-10 flex items-center justify-between pr-4">
              <div>
                <p className="text-lightgray-900/60 text-sm">Pro Plan (3 Months)</p>
                {pro_details ? <h3 className="text-[28px] font-medium">THB {(pro_details?.price_usd).toFixed(2)}</h3> : <Skeleton className="h-8 w-[120px]"/>}
              </div>
              <ChevronRight className="text-[#AFB0B4]"/>
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <Button className="regis-btn" onClick={handleRedirectToPro}>Confirm</Button>
    </section>
  )
}

export default Checkout