import { createContext, useContext, useState } from 'react';
const modalContext = createContext(null);


export const ModalProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false)
    const openModal = (path) => setOpen(path ?? true);
    const closeModal = () => setOpen(false);

    const [modalProps, _] = useState({
        primaryAction: closeModal,
    });

    const setModalProps = (props) => {
        _({
            ...modalProps,
            ...props,
        });
    }

    return (
        <modalContext.Provider
            value={{
                open,
                isSaving,
                setIsSaving,
                modalProps,
                setModalProps,
                openModal,
                closeModal,
            }}
        >
            {children}
        </modalContext.Provider>
    );
}

const useModal = () => useContext(modalContext);

export default useModal;