import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { cn } from "src/lib/utils";

export default function Loading({ isFadeIn }){
  const { t } = useTranslation()
  return (
    <div className={cn('flex justify-center items-center gap-3 text-lightgray-800', {'fade-in-loading-workspace': isFadeIn})}>
      <Loader2 className='animate-spin'/>
      <h2 className="text-lg">{t('loading')}</h2>
    </div>
  )
}