import { cn } from "src/lib/utils"
import { AccountModalMenus } from "./AccountModalMenus"
import { TableBody, Table, TableCell, TableRow } from "src/components/ui/table"

const OverviewMenu = ({ title, submenu, className }) => {
  return (
    <AccountModalMenus className={cn("p-4", className)} largeTitle={title}>
      <section className="flex items-start gap-x-3">
        <Table className="relative sm:left-20">
          <TableBody>
            {submenu.map(menu => (
              <TableRow className="hover:bg-transparent" key={menu.title}>
                <TableCell className="flex flex-col sm:flex-row justify-center sm:justify-start sm:items-center sm:-ml-20 px-0 gap-4 sm:py-4">
                  <div className='w-16'>{menu.icon}</div>
                  <div>
                    <h3 className="font-medium text-lg">{menu.title}</h3>
                    <p className="text-lightgray-900/60 text-sm">{menu.desc}</p>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
    </AccountModalMenus>
  )
}

export default OverviewMenu