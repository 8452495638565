import { user as user_api } from "src/client/api";
import * as yup from "yup"
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { AccountModalMenus } from "./topbar/components/AccountModalMenus";
import InputSection from "./InputSection";
import { Table, TableBody } from "./ui/table";
import useProvinces from "src/hooks/useProvinces";
import useModal from "src/hooks/useModal";

export function BillingAddressForm({ title, desc, billingAddress, onSubmitForm, submitText, onSettings, onTypeChange, onChangeWHT }) {
  const [isCompany, setIsCompany] = useState(false);
  const [enableTaxID, setEnableTaxID] = useState(onSettings ? true : false);
  const { t } = useTranslation();
  const [isInitialValuesSet, setIsInitialValuesSet] = useState(false);
  const [deductWHT, setDeductWHT] = useState(false);
  const provinces = useProvinces()
  const { setModalProps, setIsSaving } = useModal()

  // Initialize formik
  const formik = useFormik({
    initialValues: {
      // fullname: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      company_name: '',
      deduct_wht: '',
      address: '',
      subdistrict: '',
      district: '',
      province: 'Bangkok',
      postal_code: '',
      tax_id: '',
      tax_invoice_type: 'Personal',
    },
    onSubmit: async (values) => {
      setIsSaving(true);
      await onSubmitForm(values);  // Pass the form values to the parent
      setIsSaving(false);  // Handle the saving state
    },
  });
  useEffect(() => {
    if (billingAddress && !isInitialValuesSet) {
      formik.setValues({
        // fullname: billingAddress?.billing_name || '',
        firstname: billingAddress?.firstname || '',
        lastname: billingAddress?.lastname || '',
        email: billingAddress?.email || '',
        phone: billingAddress?.phone || '',
        company_name: billingAddress?.billing_name || '',
        deduct_wht: billingAddress?.deductwht || '',
        address: billingAddress?.address?.address_line1 || '',
        subdistrict: billingAddress?.address?.address_line2 || '',
        district: billingAddress?.address?.city || '',
        province: billingAddress?.address?.state || '',
        postal_code: billingAddress?.address?.pincode || '',
        tax_id: billingAddress?.address?.gstin || '',
        tax_invoice_type: billingAddress?.tax_invoice_type || '',
      });
      if (billingAddress?.tax_invoice_type == "Company") {
        setIsCompany(true);
      }
      if (billingAddress?.deductwht) {
        setDeductWHT(true);
      }
      setIsInitialValuesSet(true); // Set the flag to prevent further updates
    }
    setModalProps({
      primaryAction: (e) => formik.handleSubmit(e)
    })
  }, [billingAddress, isInitialValuesSet]);

  const fields = {
    addresses: ['address', 'subdistrict', 'district', 'postal_code', 'province'],
    personal: ['firstname', 'lastname'],
    company: ['company_name', 'tax-id']
  }

  const { addresses, personal, company } = fields
  const [requiredFields, setRequiredFields] = useState([...personal, ...addresses])
  const checkIfFilledAll = requiredFields.every(field => formik.values[field] !== "");

  useEffect(() => {
    isCompany ?
      setRequiredFields([...company, ...addresses]) :
      setRequiredFields([...personal, ...addresses])
  }, [isCompany, formik.values, checkIfFilledAll])
  useEffect(() => {
    if (onTypeChange) {
      onTypeChange(isCompany);
    }
    if (onChangeWHT) {
      onChangeWHT(deductWHT);
    }
    setModalProps({
      primaryAction: (e) => formik.handleSubmit(e)
    })
  }, [isCompany, onTypeChange, deductWHT]);

  const invoice_type = ["Personal", "Company"]

  const FORM_BILLING_ADDRESS = [
    { type: "text", placeholder: "First name", label: "First name", name: "firstname", defaultValue:billingAddress?.firstname, value: formik.values.firstname || '' },
    { type: "text", placeholder: "Last name", label: "Last name", name: "lastname",defaultValue:billingAddress?.lastname, value: formik.values.lastname || '' },
    { type: "email", placeholder: "Email", label: "Email", name: "email", defaultValue:billingAddress?.email, value: formik.values.email || '' },
    { type: "tel", placeholder: "Phone", label: "Phone", name: "phone",defaultValue:billingAddress?.phone, value: formik.values.phone || '' },
    { type: "text", placeholder: "Address", label: "Address", name: "address",defaultValue:billingAddress?.address?.address_line1, value: formik.values.address || '' },
    { type: "text", placeholder: "Sub-district", label: "Sub-district", name: "subdistrict",defaultValue:billingAddress?.address?.address_line2, value: formik.values.subdistrict || '' },
    { type: "text", placeholder: "District", label: "District", name: "district",defaultValue:billingAddress?.address?.city, value: formik.values.district || '' },
    {
      type: "select", placeholder: "Province", label: "Province", name: "province",defaultValue:billingAddress?.address?.state, value: t(formik.values.province) || '',
      onValueChange: value => formik.setFieldValue('province', value),
      options: provinces
    },
    { type: "text", placeholder: "Postal code", label: "Postal code", name: "postal_code", value: formik.values.postal_code || '' },
  ]

  const FORM_COMPANY_INFO = [
    {
      type: "select", placeholder: "Tax Invoice Type", label: "Tax Invoice Type", name: "tax_invoice_type",
      value: formik.values.tax_invoice_type,
      onValueChange: value => {
        formik.setFieldValue("tax_invoice_type", value);
        setIsCompany(value === 'Company');
      },
      options: invoice_type
    },
    { type: "text", placeholder: "Company name", label: "Company name", name: "company_name", value: formik.values.company_name || '' },
    { type: "tel", placeholder: isCompany ? "3-0000-9999-9" : "1-1009-99999-09-9", label: "Tax ID", name: "tax_id", value: formik.values.tax_id || '' },
  ]

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-10">
      <AccountModalMenus title={title} desc={desc} className="pl-4">
        <Table>
          <TableBody>
            {FORM_BILLING_ADDRESS.map(inp => (
              <InputSection
                key={inp.name}
                type={inp.type}
                placeholder={inp.placeholder}
                label={inp.label}
                name={inp.name}
                value={inp.value}
                onChange={formik.handleChange}
                onValueChange={inp.onValueChange || null}
                options={inp.options || null}
                error={inp.error}
              />
            ))}
          </TableBody>
        </Table>
      </AccountModalMenus>
      <AccountModalMenus className="pl-4">
        <Table>
          <TableBody>
            {FORM_COMPANY_INFO.map(inp => (
              <InputSection
                key={inp.name}
                type={inp.type}
                placeholder={inp.placeholder}
                label={inp.label}
                name={inp.name}
                value={inp.value}
                onChange={formik.handleChange}
                onValueChange={inp.onValueChange || null}
                options={inp.options || null}
                error={inp.error}
              />
            ))}
          </TableBody>
        </Table>
      </AccountModalMenus>
      {submitText && <button type="submit" className="regis-btn !max-w-none flex gap-3 items-center text-white">
        {submitText}
      </button>}
    </form>
  );
}