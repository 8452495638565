import React, { useState, useEffect } from 'react';
import { useUser } from "../../../hooks/useUser";
import { EditProfileForm } from 'src/components/editProfileForm'
import Loading from 'src/components/ui/loading';

export default function MyAccountForm() {
  const [userData, setuserData] = useState(null)
  const { user } = useUser();
  const fetchData = async () => { setuserData(await user) };
  useEffect(() => { fetchData() }, []);
  return userData ? <EditProfileForm preloadedValues={userData} /> : <Loading />
}