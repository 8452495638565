import { createContext, useContext, useState, useEffect } from 'react';
import { user as userClient } from '../client/api';
import { getToken, removeToken, setToken } from '../utils/helper';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';
import * as Sentry from "@sentry/react";

const userContext = createContext(null);


export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [team, setTeam] = useState(null);
    const [auth, setAuth] = useState(null);
    const { logOut: oauthLogout } = useContext(AuthContext);

    useEffect(() => {
        if (getToken()) {
            getUser()
        }
    }, []);

    const getUser = async () => {
        return userClient.get().then((response) => {
            setUser(response.data.message.user);
            setTeam(response.data.message.team);
            Sentry.setUser({
                id: response.data.message.user.name,
                email: response.data.message.user.email,
                username: response.data.message.user.username
            });
            delete response.data.message.user;
            setAuth(response.data.message);
            return response;
        })
    }

    const login = async (provider, params) => {
        try {
            if (provider === 'email') {
                return userClient.login(params.username, params.password).then((response) => {
                    if (response.data.token) {
                        // handle jwt
                        setToken(response.data.token);
                        // get user
                        getUser();
                    }
                    return response.data;
                })
            }
            else {
                return userClient.oauthLogin(provider, params.token, params.idToken).then((response) => {
                    if (response.data.message.token) {
                        // handle jwt
                        setToken(response.data.message.token);
                        // get user
                        getUser();
                    }
                    return response.data;
                })
            }
        } catch (error) {
            setAuth(null); /* Changed the value of setAuth to 'null' instead of 'false' */
            return error;
        }
    };

    const logout = async () => {
        return userClient.logout().then((response) => {
            setUser(null);
            Sentry.setUser(null);
            setTeam(null);
            setAuth(null); /* Changed the value of setAuth to 'null' instead of 'false' */
            removeToken();
            oauthLogout();
            window.location.href = "/login";
            return response;
        }).catch((error) => {
            setAuth(null); /* Changed the value of setAuth to 'null' instead of 'false' */
            return error;
        });
    };

    return <userContext.Provider value={{
        user,
        team,
        auth,
        login,
        logout,
        getUser
    }}>
        {children}
    </userContext.Provider>
}

export const useUser = () => useContext(userContext);