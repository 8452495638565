import { Button } from "./ui/button";
import { AccountModalMenus } from "./topbar/components/AccountModalMenus";
import UpgradeLogo from "./icon-menus/UpgradeLogo";
import { useParams } from 'react-router-dom';
import { site } from "src/client/api";
import { useQuery } from "react-query";
import { useUser } from "src/hooks/useUser";
import Loading from "./ui/loading";
import usePurchaseHistory from "src/hooks/usePurchaseHistory";

export default function InvoiceTable() {
  const { user: userAccount } = useUser()
  const { invoiceId } = useParams();
  const { userInvoices } = usePurchaseHistory()
  const { data: sites } = useQuery('sites', site.list, { enabled: false });
  const view = userInvoices?.message?.find(invoice => invoice.name === invoiceId)
  const handlePayClick = () => window.location.href = `/checkout/plan/pro/${view.name}`;
  const formatDate = (expiry) => {
    if (!expiry) return ''; // Return an empty string if no expiry date
    const date = new Date(expiry);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  if (!userInvoices?.message) return <Loading />

  return (
    <>
      <section className="flex flex-col gap-y-3">
        <AccountModalMenus className="px-4 py-6 flex flex-col gap-y-5 mb-7">
          <div className="flex items-center justify-between text-xl font-bold">
            <h3 className="flex items-center gap-x-2">
              {"Zaviago"}
              <UpgradeLogo />
            </h3>
            <p>THB {(view.total).toFixed(2)}</p>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-semibold text-sm">WorkSpace Pro Plan</h3>
              <p className="text-lightgray-900/60 text-xs">For {sites ? sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name : ''}</p>
            </div>
            <p className="text-sm">THB 75.00</p>
          </div>

          {/* {view.purchase_info.map(info => (
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-semibold text-sm">VAT charged at 7%</h3>
                <p className="text-lightgray-900/60 text-xs">Shopee / Lazada / TikTok</p>
              </div>
              <p className="text-sm">THB 75.00</p>
            </div>
          ))} */}

          <div className="flex items-center justify-between">
            <h3>VAT charged at 7%</h3>
            <p className="text-sm">THB {(view.total * 0.07).toFixed(2)}</p>
          </div>
          <div className="flex items-center justify-between">
            <h3>Total</h3>
            <p className="text-sm">THB {(view.total).toFixed(2)}</p>
          </div>
        </AccountModalMenus>

        <AccountModalMenus className="px-4 py-6 flex flex-col gap-y-5">
          <div className="grid grid-cols-2">
            <div>
              <h2 className="text-lightgray-900/60 text-sm">Order Date</h2>
              <p>{formatDate(view.date)}</p>
            </div>
            <div>
              <h2 className="text-lightgray-900/60 text-sm">Order ID</h2>
              <p>{view.name}</p>
            </div>
          </div>

          <div>
            <h2 className="text-lightgray-900/60 text-sm">Zaviago Account</h2>
            <p>{userAccount.email}</p>
          </div>

          <div>
            <h2 className="text-lightgray-900/60 text-sm">Billing address</h2>
            <p>
              Zaviago.co.ltd<br />
              999/99 rama9, bangtrading group<br />
              Floor 2<br />
              Suanluang Bangkok 10250<br />
              Thailand
            </p>
            {/* {userBilling && (
              <p>
                {userBilling?.message.address_line1}<br/>
                {userBilling?.message.address_line2}<br/>
                {`${userBilling?.message.city} ${userBilling?.message.state} ${userBilling?.message.pincode}`}<br/>
                {userBilling?.message.country}<br/>
              </p>
            )} */}
          </div>
        </AccountModalMenus>

        {(view.bank_invoice === null && view.status !== 'Canceled') && (
          <Button className="regis-btn !max-w-none !w-full" onClick={handlePayClick}>Pay</Button>
        )}
      </section>
    </>
  )
}